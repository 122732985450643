import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button, Divider, Header, Icon } from 'semantic-ui-react';
import { SiteData, withRouteData } from '@stoplight/react-static';

import ErrorMessage from '@platform/components/ErrorMessage';
import FormInput from '@platform/components/FormInput';

import { HubBranding } from '@platform/format-hubs/components/HubBranding';

class Login extends React.Component {
  componentWillMount() {
    const { clearUi } = this.props;

    if (clearUi) clearUi();
  }

  clearError = () => {
    const { updateUi, routerStore } = this.props;

    routerStore.setQueryParams({ error: undefined });
    updateUi('unset', 'error');
  };

  login = (provider = 'basic', data = {}) => {
    if (typeof window === 'undefined') return;

    const { updateUi, routerStore } = this.props;

    this.clearError();

    updateUi('set', 'loggingIn', true);

    axios({
      method: 'post',
      url: `/_/${provider}/login`,
      data,
    })
      .then(res => {
        window.location.pathname = _.get(routerStore, 'location.query.r', '/');
      })
      .catch(err => {
        updateUi('unset', 'loggingIn');
        updateUi('set', 'error', err);
      });
  };

  renderSaml = () => {
    const { ui, auth } = this.props;
    const { loggingIn } = ui;

    return (
      <div key="saml" className="SamlLogin">
        <Button
          fluid
          secondary={auth.default === 'saml'}
          className="AccountForm-cta"
          disabled={loggingIn}
          onClick={() => {
            window.location.pathname = '/_/saml/login';
          }}
          content={auth.buttonText || 'Click here to log in'}
        />
      </div>
    );
  };

  renderAuth0 = () => {
    const { ui, auth } = this.props;
    const { loggingIn } = ui;

    return (
      <div key="auth0" className="Auth0Login">
        <Button
          fluid
          secondary={auth.default === 'auth0'}
          className="AccountForm-cta"
          disabled={loggingIn}
          onClick={() => {
            window.location.pathname = '/_/auth0/login';
          }}
          content={auth.buttonText || 'Click here to log in'}
        />
      </div>
    );
  };

  renderBasic = () => {
    const { ui, updateUi, auth } = this.props;
    const { username = '', password = '', loggingIn } = ui;

    return (
      <form
        key="basic"
        className="BasicLogin"
        onSubmit={e => {
          e.preventDefault();

          this.login('basic', { username, password });
        }}
      >
        <div className="AccountForm-input-group">
          <FormInput
            id="login-user"
            value={username}
            onChange={e => {
              updateUi('set', 'username', _.trim(e.target.value));
            }}
            disabled={loggingIn}
            placeholder="Username"
            fluid
          />

          <FormInput
            id="login-pass"
            type="password"
            value={password}
            onChange={e => {
              updateUi('set', 'password', _.trim(e.target.value));
            }}
            disabled={loggingIn}
            placeholder="Password"
            fluid
          />
        </div>

        <div className="pt-3">
          <Button
            fluid
            type="submit"
            disabled={loggingIn}
            className="AccountForm-cta"
            secondary={auth.default === 'basic'}
          >
            {auth.buttonText || 'Click here to log in'} <Icon name="power" />
          </Button>
        </div>
      </form>
    );
  };

  renderAuth = type => {
    let component = null;

    switch (type) {
      case 'basic':
        component = this.renderBasic();
        break;

      case 'auth0':
        component = this.renderAuth0();
        break;

      case 'saml':
        component = this.renderSaml();
        break;

      default:
        break;
    }

    return component;
  };

  render() {
    const { ui, auth = {}, routerStore, whitelabel, namespace } = this.props;
    const error = ui.error || _.get(routerStore, 'location.query.error');

    const components = [];

    let defaultProvider;
    if (auth.default && auth[auth.default]) {
      defaultProvider = auth.default;

      components.push(this.renderAuth(defaultProvider));
    }

    _.forEach(auth, (data, provider) => {
      if (provider === 'default' || provider === defaultProvider) return;

      const authComponent = this.renderAuth(provider);
      if (!authComponent) return;

      if (components.length) {
        components.push(
          <Divider key={`divider-${provider}`} className="AccountForm-divider" horizontal>
            OR
          </Divider>
        );
      }

      components.push(authComponent);
    });

    return (
      <React.Fragment>
        <div className="Login px-4 h-full flex flex-col items-center justify-center m-auto w-full bg-white">
          <div className="AccountForm">
            {auth.title && (
              <div className="LoginHeader mb-16 border-b">
                <h1 className="LoginHeader-title">{auth.title}</h1>
                {auth.subtitle && <div className="LoginHeader-subtitle pb-4">{auth.subtitle}</div>}
              </div>
            )}

            <ErrorMessage className="mb-6" error={error} />

            {components}
          </div>
        </div>

        <HubBranding whitelabel={whitelabel} namespace={namespace} centered />
      </React.Fragment>
    );
  }
}

const InjectedLogin = inject((stores, props) => {
  const { appStore, routerStore } = stores;

  return {
    routerStore,
    ...appStore.injectUi('BasicAuth-Login'),
  };
})(observer(Login));

export default withRouteData(routeData => {
  return <SiteData {...routeData} component={InjectedLogin} />;
});
